import React from 'react'
import './joins.css';
import emailjs from '@emailjs/browser';

const Join = () => {
    const form = React.useRef();
    const sendemail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_t7ltanh','template_fulfqwl',form.current,'ImdG6Lp8ghuRfX4Rc').then((result)=>{
            console.log(result.text);
        },(error) =>{
            console.log(error.text);
        });
    };

  return (
    <div className="join" id="join-us">
        <div className="left-j">
            <hr/>
            <div>
                <span className="stroke-text">READY TO</span>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span className="stroke-text">WITH US ?</span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} className="email-container" onSubmit={sendemail}>
                <input type="email" name="user_email" placeholder="Enter Your Email Address : "></input>
                <button className="btn btn-j">Join Now</button>
            </form>



        </div>
    </div>
  )
}

export default Join